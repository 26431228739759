import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  _data: any;
  selectedRole: string;  route:string;
  constructor(private router: Router, private location: Location) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.selectedRole='admin';
   
    this._data = JSON.parse(sessionStorage.getItem('authUser'));
//     debugger;
//     if(this._data != null){
    
//       this.selectedRole=this._data.rolename;
//     }
//     else{
//       this.router.navigateByUrl('signin');
//     }

//   }
// }

if(this._data != null){
    
      this.selectedRole=this._data.rolename;
    }
    else{
      this.route = this.location.path();
      if(this.route.includes('register')){
        this.router.navigateByUrl(this.route);
      }
      else if(this.route.includes('password-reset')){this.router.navigateByUrl(this.route);}
      else if(this.route.includes('verify-user')){this.router.navigateByUrl(this.route);}
      else if(this.route.includes('forget')){this.router.navigateByUrl(this.route);}
      else if(this.route.includes('view-files')){this.router.navigateByUrl(this.route);}
      else{
      this.router.navigateByUrl('signin');
      }
    }

  }
}

