import { Component, OnInit } from '@angular/core';
import { navItems, navItemsuser } from '../../_nav';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = navItems;
  public navItemsuser = navItemsuser;
  public selectedRole: string;

  constructor(private router: Router) { }
  userDisplayName = '';
  currentYear: any
  ngOnInit() {

    var _data = JSON.parse(sessionStorage.getItem('authUser'));
    this.userDisplayName = _data.name;
    if (_data != null) {
      this.selectedRole = _data.rolename;
    } else { this.router.navigateByUrl('signin'); }
    const d = new Date();
    this.currentYear = d.getFullYear();
  }



  logout() {
    sessionStorage.clear();

    this.router.navigateByUrl('signin');
  }


  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
