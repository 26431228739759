import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl,FormBuilder } from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { Router,ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import { Subscription } from 'rxjs/Subscription'
@Component({
  selector: 'app-dashboard',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;message: string;showMsg:boolean;paramvalue:string;private timer: Observable<any>; private subscription: Subscription; public cm:any;
  constructor(private http: HttpClientModule,private route:ActivatedRoute, private router:Router,private fb: FormBuilder,private authsSrvice:AuthService) { }
 
  ngOnInit(){
    this.hideloader();
    this.registerForm = new FormGroup({
      username: new FormControl('', [
        Validators.required
       
          ])
    })
    const QryValue: string = this.route.snapshot.queryParamMap.get('cid');
    if (QryValue!=null)
    {
this.cm=QryValue;
    }
   
  }
  register()
  {
    this.showloader();
    const QryValue: string = this.route.snapshot.queryParamMap.get('cid');
    if (QryValue!=null)
    {
    const sregisterData = this.registerForm.value;
    var apiParams={"email":sregisterData.username,"camera_id":QryValue};
    
    this.authsSrvice.isUserAuthenticated('assign-camera-customer',apiParams).then((result)=>{
      if(result.Result=="Success"){
        this.hideloader();
        this.message=result.Message;
        this.showMsg= true; this.messages();this.registerForm.reset();
      }
      else{
        this.hideloader();
        this.message=result.Message;
        this.showMsg= false;this.messages();
      }
    }).catch(error => console.log(error));

  }
  else  {
    this.message="Invalid Request, Plesae try with a valid URL!";
        this.showMsg= false;this.messages();this.hideloader();
  }
}
  
  cancel() {
    this.router.navigateByUrl('signin');
  }
  hideloader() { 
        
    document.getElementById('loading') 
        .style.display = 'none'; 
}

showloader() { 
  
  document.getElementById('loading') 
      .style.display = 'block'; 
}

messages(){

  if(document.getElementById('demo').style.display === 'none')
  {
    document.getElementById('demo').style.display = 'block'; 
  }
  else    {
    this.timer = Observable.timer(5000); 
  }
  this.timer = Observable.timer(5000); 
  this.subscription = this.timer.subscribe(() => {     
   document.getElementById('demo').style.display = 'none'; 
   
});
}
}
