import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl,FormBuilder } from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-dashboard',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.css']
})
export class ForgetComponent implements OnInit {

  forgetForm: FormGroup;message: string;showMsg:boolean;private timer: Observable<any>; private subscription: Subscription;
  constructor(private http: HttpClientModule, private router:Router,private fb: FormBuilder,private authsSrvice:AuthService) { }


  ngOnInit(){
    this.hideloader();
    this.forgetForm = new FormGroup({
      username: new FormControl('', [
        Validators.required
       
          ])
     
      
	  
    })
  }
  hideloader() { 
        
    document.getElementById('loading') 
        .style.display = 'none'; 
}

showloader() { 
  
  document.getElementById('loading') 
      .style.display = 'block'; 
}
  forget()
  {
    this.showloader();
    
    const sregisterData = this.forgetForm.value;
    var apiParams={"Email":sregisterData.username};
    this.authsSrvice.isUserAuthenticated('forget-password',apiParams).then((result)=>{
      if(result.Result=="Success"){
        this.hideloader();
        this.message=result.Message;
        this.showMsg= true; this.messages();
        this.forgetForm.reset();
      }
      else{
        document.getElementById('demo').style.display = 'block'; 
        this.hideloader();
        this.message=result.Message; this.messages();
        this.showMsg= false;this.forgetForm.reset();
      }

    }).catch(error => console.log(error));

  }
  cancel()
  {
    this.router.navigateByUrl('signin');
  }

  messages(){
    
    if(document.getElementById('demo').style.display === 'none')
    {
      document.getElementById('demo').style.display = 'block'; 
    }
    else    {
      this.timer = Observable.timer(5000); 
    }
    this.timer = Observable.timer(5000); 
    this.subscription = this.timer.subscribe(() => {     
     document.getElementById('demo').style.display = 'none'; 
     //this.router.navigateByUrl('signin');
 });
  }
}
