import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl,FormBuilder } from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import { Subscription } from 'rxjs/Subscription';
@Component({
  selector: 'app-dashboard',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  message: string;showMsg:boolean;private timer: Observable<any>;private subscription: Subscription;
  constructor(private http: HttpClientModule, private router:Router,private fb: FormBuilder,private authsSrvice:AuthService) { }

  ngOnInit() {
    this.hideloader();
    this.signinForm = new FormGroup({
      username: new FormControl('', [
        Validators.required
       
          ]),
          password: new FormControl('', [Validators.required,
            Validators.minLength(6),Validators.maxLength(50)
          ]),
     
      rememberMe: new FormControl(false)
	  
    })

//     this.ApplicationKey=sessionStorage.getItem('Apikey');
// if(this.ApplicationKey){
//   this.router.navigateByUrl('orders');
// }


  }


  hideloader() { 
        
    document.getElementById('loading') 
        .style.display = 'none'; 
}

showloader() { 
  
  document.getElementById('loading') 
      .style.display = 'block'; 
}



  signin() {
    
    this.showloader();
    const signinData = this.signinForm.value;
    var apiParams={"Email":signinData.username,"Password":signinData.password};
    
    this.authsSrvice.isUserAuthenticated('login',apiParams).then((result)=>{
   
    if(result.Result=="Success"){
      this.hideloader();
      this.message=result.Message;
      this.showMsg= true;
      result.Email=signinData.email;
      var authData= JSON.stringify(result.data);
      sessionStorage.setItem('authUser', authData);
      sessionStorage.setItem('Email',signinData.email);
      sessionStorage.setItem('IsUserlogged',result.Result);
      sessionStorage.setItem("CustId",result.data.id);
      // sessionStorage.setItem("api_token",result.token);
      sessionStorage.setItem("api_token",result.data.api_token);
      this.router.navigateByUrl('dashboard');
      
    }else{
      this.message=result.Message;
      this.showMsg= false; this.signinForm.reset();
      this.hideloader();
      this.messages();
    }
    }).catch(error => console.log(error));
     
  }

  register() {
    this.router.navigateByUrl('register');
  }
  forget()
  {
    this.router.navigateByUrl('forget');
  }

  messages(){
  
    if(document.getElementById('demo').style.display === 'none')
    {
      document.getElementById('demo').style.display = 'block'; 
    }
    else    {
      this.timer = Observable.timer(5000); 
    }
  
    var ss=this.message;
    this.timer = Observable.timer(5000); 
    this.subscription = this.timer.subscribe(() => {     
     document.getElementById('demo').style.display = 'none'; 
     
  });
  }

}
