import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Http, Response, Headers, RequestOptions,Request, RequestMethod } from "@angular/http";
import { Router,ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver'; 
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import { Subscription } from 'rxjs/Subscription';
@Component({
  selector: 'app-processor-image-view',
  templateUrl: './processor-image-view.component.html',
  styleUrls: ['./processor-image-view.component.css']
})
export class ProcessorImageViewComponent implements OnInit {

  fileUrl:any;
  images:any[]=[];
  loading:boolean=true;
  result:any="Success";message: string;showMsg:boolean;private timer: Observable<any>; private subscription: Subscription;
  constructor(private httpp: Http, private http: HttpClientModule, private route:ActivatedRoute, private router:Router, private sanitizer: DomSanitizer) { }
 
  ngOnInit(): void {
    const id: string = this.route.snapshot.queryParamMap.get('id');

    if (id !== null && id !== ""){
      this.callForPics(id);
    }
  }

  callForPics(id):void{
    
    this.httpp.post(environment.API_ENDPOINT+`download-images`,{
      id:id
    }).subscribe((data:any)=>{
      if (data.status == 200){
        let result = JSON.parse(data._body);
        if (result.Result == "Success"){
          this.result = result.Result;
          // this.images = result.data;
          // let urls=[]
          // result.data.forEach(element => {
          //   urls.push({
          //     'link':element,
          //     'download':this.sanitizer.bypassSecurityTrustResourceUrl(element)
          //   })
          // });
          this.images = result.data;
          this.loading = false;
        }
        else{
          this.message=result.Message;
          this.showMsg= false;
          this.messages();this.hideloader();

        }
      }
    })
  }

  hideloader() { 
        
    document.getElementById('loading') 
        .style.display = 'none'; 
}

showloader() { 
  
  document.getElementById('loading') 
      .style.display = 'block'; 
}
 downloadImg(downloadLink:any):void{
    
    var linkElement = document.createElement('a');
    linkElement.setAttribute('href', downloadLink);
    linkElement.setAttribute('target', '_blank');
    linkElement.setAttribute("download", "file.jpg");
    linkElement.click();
  }



  downloadImg2(downloadLink:any):void{
    
    //saveAs(downloadLink, "image.jpg");
    var linkElement = document.createElement('a');
    linkElement.setAttribute('href', 'data:application/octet-stream,' + encodeURIComponent(downloadLink));
    linkElement.style.display = 'none';
    linkElement.setAttribute("download", downloadLink);
    document.body.appendChild(linkElement);
    setTimeout(function(){ 
      linkElement.click();
      document.body.removeChild(linkElement);

    }, 3000);
    

    
  }

  messages(){
    
    if(document.getElementById('demo').style.display === 'none')
    {
      document.getElementById('demo').style.display = 'block'; 
    }
    else    {
      this.timer = Observable.timer(5000); 
    }
    this.timer = Observable.timer(5000); 
    this.subscription = this.timer.subscribe(() => {     
     document.getElementById('demo').style.display = 'none'; 
     //this.router.navigateByUrl('signin');
 });
  }

}
