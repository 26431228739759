import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl,FormBuilder } from '@angular/forms';
import {AuthService} from '../../services/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import { Router, ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/timer';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-dashboard',
  templateUrl: './verifyuser.component.html',
  styleUrls: ['./verifyuser.component.css']
})
export class VerifyuserComponent implements OnInit {

  loginForm: FormGroup;message: string;showMsg:boolean;private timer: Observable<any>; private subscription: Subscription;
  constructor(private http: HttpClientModule,private route:ActivatedRoute, private router:Router,private fb: FormBuilder,private authsSrvice:AuthService) { }


  ngOnInit(){
    this.hideloader();
    this.loginForm = new FormGroup({
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30)
      ])),
      confirmpassword: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(30)
      ])),     
      
              
              
              
    }),{ 
      validators: this.password.bind(this)
    };
  }


  password(formGroup: FormGroup) {
    this.showloader();
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmpassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }
  error_messages = {
    'password': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password length min 6.' },
      { type: 'maxlength', message: 'Password length min 6.' }
    ],
    'confirmpassword': [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: 'Password length min 6.' },
      { type: 'maxlength', message: 'Password length min 6.' }
    ],
  }
  update()
  {

    this.showloader();
    const AddUserData = this.loginForm.value;
    const QryValue: string = this.route.snapshot.queryParamMap.get('token');
    if (QryValue!=null)
    {
    if(AddUserData.password==AddUserData.confirmpassword){
      var apiParams={"token":QryValue,"password":AddUserData.password};
      this.authsSrvice.isUserAuthenticated('verify',apiParams).then((result)=>{
        if(result.Result=="Success"){
          this.hideloader();
          this.message=result.Message;
        this.showMsg= true; this.messages(); this.loginForm.reset();
        }
        else{
          this.message=result.Message; this.messages();
          this.showMsg= false;this.hideloader();
          this.messages();this.loginForm.reset();
        }

      }).catch(error => console.log(error));
    }
    else{
      this.message="Password mismatch!";
      this.showMsg= false;
      this.hideloader();
       this.loginForm.reset();
      this.messages();
    }
  }
  else{

  }
  }
  messages(){

    if(document.getElementById('demo').style.display === 'none')
    {
      document.getElementById('demo').style.display = 'block'; 
    }
    else    {
      this.timer = Observable.timer(5000); 
    }
    this.timer = Observable.timer(5000); 
    this.subscription = this.timer.subscribe(() => {     
     document.getElementById('demo').style.display = 'none'; 
    // this.router.navigateByUrl('signin');
 });
  }

  hideloader() { 
        
    document.getElementById('loading') 
        .style.display = 'none'; 
}

showloader() { 
  
  document.getElementById('loading') 
      .style.display = 'block'; 
}
cancel()
{
  this.router.navigateByUrl('signin');
}

}
