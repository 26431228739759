import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { SigninComponent } from './views/signin/signin.component';
import { RegisterComponent } from './views/register/register.component';
import { ForgetComponent } from './views/forget/forget.component';
import { UpdatepasswordComponent } from './views/updatepassword/updatepassword.component';
import { VerifyuserComponent } from './views/verifyuser/verifyuser.component';

import { ProcessorImageViewComponent } from './views/processor-image-view/processor-image-view.component';

//import { UserComponent } from './views/user/user.component';
export const routes: Routes = [

 {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },

  
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'signin',
    component: SigninComponent,
    data: {
      title: 'Login Page'
    }
  },

  
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },


  {
    path: 'password-reset',
    component: UpdatepasswordComponent,
    data: {
      title: 'Update Password'
    }
  },

  {
    path: 'verify-user',
    component: VerifyuserComponent,
    data: {
      title: 'Update Password'
    }
  },
  {
    path: 'forget',
    component: ForgetComponent,
    data: {
      title: 'Forget Page'
    }
  },
  {
    path:'view-files',
    component:ProcessorImageViewComponent,
    data:{
      title:'Processor File Viewer'
    }
  },


  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [

      {
        path: 'user',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'adduser',
        loadChildren: () => import('./views/user/adduser/adduser.module').then(m => m.AdduserModule)
      }
      ,
      {
        path: 'camera',
        loadChildren: () => import('./views/camera/camera.module').then(m => m.CameraModule)
      },

      {
        path: 'addcamera',
        loadChildren: () => import('./views/camera/addcamera/add-camera.module').then(m => m.AddCameraModule)
      }
      ,
      {
        path: 'importcamera',
        loadChildren: () => import('./views/camera/importcamera/add-import-camera.module').then(m => m.AddImportCameraModule)
      }
      ,
      {
        path: 'pcl',
        loadChildren: () => import('./views/processor-image-list/processor-image-list.module').then(m => m.ProcessorImageListModule)
      }
      ,
      {
        path: 'ccreport',
        loadChildren: () => import('./views/customercamerareport/customercamerareport.module').then(m => m.CustomercamerareportModule)
      }
      ,

      {
        path: 'piu',
        loadChildren: () => import('./views/processor-image-list/addprocessorimage/addprocessorimage.module').then(m => m.AddprocessorimageModule)
      }
      ,


      {
        path: 'managecamAssgn',
        loadChildren: () => import('./views/managecameraassignment/managecameraassignment.module').then(m => m.ManagecameraassignmentModule)
      }
      ,
      {
        path: 'addcamAssgn',
        loadChildren: () => import('./views/managecameraassignment/addcameraassignment/addcameraassignment.module').then(m => m.AddcameraassignmentModule)
      }
      ,

      {
        path: 'managesendlist',
        loadChildren: () => import('./views/sendemaillist/sendemaillist.module').then(m => m.SendemaillistModule)
      }
      ,


      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      },

      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path:'maileditor',
        loadChildren:()=>import('./views/mail-editor/mail-editor.module').then(m=>m.MailEditorModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
