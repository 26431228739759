// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //https://api.analogcc.com/api/login
  API_ENDPOINT:'https://api.analogcc.com/api/',
  // API_ENDPOINT:'http://192.168.1.8/demo/analog-app/Solutions/Webapi/api/',
  squareredirectUrl:'https://app.analogcc.com/#/register?cid=',

  //API_ENDPOINT:'http://lvc1.acapqa.net/analogcc/api/',  
  //squareredirectUrl:'http://lvc1.acapqa.net/AnalogCCApp/#/register?cid=',
  

  accessKeyId:'AKIA4TYSA3QC5T2VFXNX',
  secretAccessKey:'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',
  region:'us-east-1',
  Bucket:'analogbucket2023',

};


